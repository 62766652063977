import { useMemo } from 'react';
import { Tooltip } from '@decub8/ui';

import { useAppSelector } from '@src/hooks';
import { getPoolMultiplier } from '@src/utils/staking';

import { findNft } from '../util';

export const NFTTooltip: React.FC<{
    id: number;
    children?: React.ReactNode;
}> = ({ children, id }) => {
    const { pools } = useAppSelector((state) => state.staking);
    const pool = pools.find(({ id: curr }) => curr === id);
    const { apr, nft_multiplier_used, nft_indexes } = pool;

    const { boost, has_boost } = useMemo(() => getPoolMultiplier(pool), [pool]);

    const APR_with_boost = (parseFloat(apr) + boost).toFixed(2) + '%';

    const [start, end] = nft_indexes || [0, 0];
    const nft_name = nft_multiplier_used ? findNft(start, end) : 'None';

    return (
        <Tooltip
            text={`<div class="p-1"><div class="flex space-x-4"><div class="flex-1 text-secondary">Required NFT</div><div class="${
                has_boost ? 'text-success' : ''
            }"><strong class="text-primary">${nft_name}</strong></div></div><div class="flex space-x-4 mt-2"><div class="flex-1 text-secondary">APY with boost</div><div class="${
                has_boost ? 'text-success' : ''
            }"><strong class="text-primary">${APR_with_boost}</strong></div></div></div>`}
        >
            {children}
        </Tooltip>
    );
};
