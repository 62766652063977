import React, { useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';

import { Button } from '@src/components';
import { useStakingMethods } from '@src/components/Staking/hooks';
import { staking_contract_versions } from '@src/constants';
import { updatePool } from '@src/features/staking';
import {
    useAppDispatch,
    useAppSelector,
    useDecubateContract,
} from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { executeTx } from '@src/utils/web3';

export const Withdraw: React.FC = () => {
    const [left_loading, setLeftLoading] = useState(false);

    const { contract_manager } = useGlobalContext();
    const { account } = useWeb3Onboard();
    const dispatch = useAppDispatch();

    const { current_pool, pools } = useAppSelector((state) => state.staking);

    const {
        id,
        user_stake = [0],
        type,
        can_claim,
        last_user_deposit,
        lock_period,
        is_unlocked,
        contract_idx,
    } = pools.find(({ id }) => id === current_pool);

    // contract instances
    const [staking] = useDecubateContract(
        staking_contract_versions[type],
        true,
    );

    const { handleWithdraw, loading } = useStakingMethods();

    const handleClaim = async () => {
        setLeftLoading(true);
        await executeTx(
            staking.claim(contract_idx),
            `Successfully claimed rewards from pool`,
            async () => {
                dispatch(updatePool(id, type, account, contract_manager));
            },
        );

        setLeftLoading(false);
    };

    const past_end_date = dayjs().isAfter(
        dayjs(last_user_deposit || new Date()).add(
            parseInt(lock_period),
            'days',
        ),
    );

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(type);

    const has_claim = ['v1', 'liquidity', 'legacy-liquidity'].includes(type);
    const cant_withdraw =
        BigNumber.from(user_stake[0]).eq(0) ||
        (is_liquidity && !is_unlocked && !past_end_date);

    const is_claimable = is_liquidity
        ? !is_unlocked || past_end_date
        : can_claim;

    return (
        <div className="flex space-x-2">
            {has_claim && (
                <Button
                    className="flex-1"
                    loading={left_loading}
                    disabled={left_loading || !is_claimable}
                    onClick={handleClaim}
                >
                    {type === 'v1' && '1.'} Claim
                </Button>
            )}
            <Button
                className="flex-1"
                disabled={loading || cant_withdraw}
                onClick={handleWithdraw}
                loading={loading}
            >
                <strong data-testid="staking-withdraw-button">
                    {type === 'v1' && '2.'} Withdraw
                </strong>
            </Button>
        </div>
    );
};
