import React from 'react';

import { useAppSelector } from '@src/hooks';

import { LiquidityDeposit } from './LiquidityDeposit';
import { StandardDeposit } from './StandardDeposit';

export const Deposit: React.FC = () => {
    const { current_pool, pools } = useAppSelector((state) => state.staking);

    const { type } = pools.find(({ id }) => id === current_pool);

    switch (type) {
        case 'liquidity':
            return <LiquidityDeposit />;
        case 'legacy-liquidity':
            return <LiquidityDeposit />;
        default:
            return <StandardDeposit />;
    }
};
