import { useEffect } from 'react';
import { commify, parseUnits } from '@ethersproject/units';

import { CurrencyInput } from '@src/components';
import { CONTRACT } from '@src/config';
import {
    setDepositAmount,
    setToken1DepositAmount,
} from '@src/features/staking';
import {
    useAppDispatch,
    useAppSelector,
    usePairReserves,
    useTokenBalance,
} from '@src/hooks';
import { Reserves, StakingPool } from '@src/ts/interfaces';
import { ChangeEvent } from '@src/ts/types';
import { parseBalance, removeCommas } from '@src/utils/web3';

import { parseNativeToken } from '../util';

import { Token1Input } from './Token1Input';

const Token0Input: React.FC<{
    pool: StakingPool;
    reserves: Reserves;
}> = ({ pool, reserves }) => {
    const { loading, deposit_amount } = useAppSelector(
        (state) => state.staking,
    );
    const dispatch = useAppDispatch();

    const [token0, token1] = pool?.input_token || [
        CONTRACT.BaseToken[pool.chain_id],
        CONTRACT.BaseToken[pool.chain_id],
    ];
    const [is_native, pos] = pool?.is_native || [false, 0];

    const deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        token0.decimals,
    );

    const balance = useTokenBalance(
        token0.address,
        pool?.chain_id,
        is_native && pos === 0,
    );

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(pool?.type);

    const more_than_available = deposit.gt(balance);

    const setToken1Amount = (amount: string) => {
        const amount_token1 = parseUnits(removeCommas(amount), token0.decimals)
            .mul(reserves.reserve1)
            .div(reserves.reserve0.eq(0) ? 1 : reserves.reserve0);

        dispatch(
            setToken1DepositAmount(
                parseBalance(amount_token1, token1?.decimals || 18, 6),
            ),
        );
    };

    const handleChange = (e: ChangeEvent) => {
        let amount = parseFloat(removeCommas(e.target.value));
        amount = isNaN(amount) ? 0 : amount;
        dispatch(setDepositAmount(commify(amount)));
        if (is_liquidity) setToken1Amount(amount.toString());
    };

    const handleMaxClick = () => {
        const parsed_balance = commify(
            removeCommas(
                parseBalance(balance, token0.decimals, is_liquidity ? 3 : 2),
            ),
        );

        dispatch(
            // eval wei balance will reduce amount if rounded up
            setDepositAmount(parsed_balance),
        );

        if (is_liquidity) setToken1Amount(parsed_balance);
    };

    const symbol =
        is_native && pos === 0
            ? parseNativeToken(token0.symbol)
            : token0.symbol;

    useEffect(() => {
        if (deposit.gt(0)) {
            setToken1Amount(deposit_amount);
        }
    }, []);

    return (
        <>
            <div className="w-full">
                <CurrencyInput
                    loading={loading}
                    valid={!more_than_available}
                    value={deposit_amount}
                    onChange={(e) => handleChange(e)}
                    onMaxClick={handleMaxClick}
                    currency={symbol}
                />
            </div>
            <div
                className={`flex items-start mt-4 text-sm ${
                    is_liquidity ? 'mb-4' : ''
                }`}
            >
                <div className="flex-1 text-sm text-secondary">
                    Available to stake
                </div>
                <strong
                    className={`${more_than_available ? 'text-accent' : ''}`}
                >
                    {`${parseBalance(
                        balance,
                        token0.decimals,
                        is_liquidity ? 3 : 2,
                    )} ${symbol}`}
                </strong>
            </div>
        </>
    );
};

export const Input: React.FC<{
    pool: StakingPool;
}> = ({ pool }) => {
    const reserves = usePairReserves(pool?.pair, pool?.chain_id);
    const [token0, token1] = pool?.input_token || [
        CONTRACT.BaseToken[pool.chain_id],
        CONTRACT.BaseToken[pool.chain_id],
    ];
    const [is_native, pos] = pool?.is_native || [false, 0];

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(pool?.type);

    return (
        <div className="text-left">
            <Token0Input pool={pool} reserves={reserves} />
            {is_liquidity && (
                <Token1Input
                    chain_id={pool?.chain_id}
                    decimals={token0.decimals}
                    token={token1}
                    reserves={reserves}
                    is_native={is_native && pos === 1}
                />
            )}
        </div>
    );
};
