import { Button, Typography } from '@decub8/ui';

export const Message: React.FC<{
    message: string;
    sub_message: string;
    handleClick: () => void;
}> = ({ message, sub_message, handleClick }) => {
    return (
        <div>
            <Typography size="md" allBold>
                {message}
            </Typography>
            <Typography variant="secondary" className="mt-2">
                {sub_message}
            </Typography>
            <Button onClick={handleClick} className="w-full mt-5">
                Continue
            </Button>
        </div>
    );
};
