import React from 'react';
import { Tooltip } from '@decub8/ui';

import { setModalState } from '@src/features/staking';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { StakingModalTabs } from '@src/ts/types';

import { useBounty } from './hooks';

export const Switch: React.FC = () => {
    const dispatch = useAppDispatch();

    const {
        current_pool,
        pools,
        modal_state: current,
    } = useAppSelector((state) => state.staking);

    const { chain_id, id, type, contract_idx, has_stake, can_claim } =
        pools.find(({ id }) => id === current_pool);

    const { bounty } = useBounty(contract_idx, chain_id, id, type);

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(type);

    const is_compound = ['compound', 'legacy-compound'].includes(type);

    const locked = is_liquidity ? !has_stake : !(has_stake && can_claim);

    return (
        <div
            className={`bg-surface-level-one flex rounded cursor-pointer  ${
                (current === StakingModalTabs.Deposit && 'mb-4') || ''
            }`}
        >
            <div
                data-testid="deposit-div"
                onClick={() =>
                    dispatch(setModalState(StakingModalTabs.Deposit))
                }
                className={`flex-1 px-4 py-2 rounded border-2 border-surface-level-one ${
                    current === 'Deposit'
                        ? 'bg-surface-level-two'
                        : 'text-secondary bg-surface-level-one'
                }`}
            >
                {current === StakingModalTabs.Deposit ? (
                    <strong>Deposit</strong>
                ) : (
                    'Deposit'
                )}
            </div>
            {is_compound && bounty.gt(0) && (
                <div
                    data-testid="bounty-div"
                    onClick={() =>
                        dispatch(setModalState(StakingModalTabs.Bounty))
                    }
                    className={`flex-1 px-4 py-2 rounded border-2 border-surface-level-one ${
                        current === 'Bounty'
                            ? 'bg-surface-level-two'
                            : 'text-secondary bg-surface-level-one'
                    }`}
                >
                    {current === StakingModalTabs.Bounty ? (
                        <strong>Bounty</strong>
                    ) : (
                        'Bounty'
                    )}
                </div>
            )}
            <div
                data-testid="withdraw-div"
                onClick={() =>
                    !locked &&
                    dispatch(setModalState(StakingModalTabs.Withdraw))
                }
                className={`flex-1 px-4 py-2 rounded border-2 border-surface-level-one ${
                    current === 'Withdraw'
                        ? 'bg-surface-level-two'
                        : 'text-secondary bg-surface-level-one'
                }`}
            >
                <Tooltip
                    text={
                        locked
                            ? `You can withdraw the deposited amount and rewards after the lock date has expired.`
                            : undefined
                    }
                >
                    <div className="flex items-center">
                        {locked && (
                            <svg
                                data-testid="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                        <div className="flex-1">
                            {current === StakingModalTabs.Withdraw ? (
                                <strong>Withdraw</strong>
                            ) : (
                                'Withdraw'
                            )}
                        </div>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};
