import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { Card, DataLines, Typography } from '@decub8/ui';

import { setStakingModalOpen } from '@src/features/layout';
import {
    setDepositAmount,
    setModalState,
    setToken1DepositAmount,
    setWithdrawPercent,
} from '@src/features/staking';
import { useAppDispatch, useAppSelector } from '@src/hooks';
import { StakingModalTabs } from '@src/ts/types';

import { SliderInput } from '../..';

import { Footer } from './Footer';
import { useBounty } from './hooks';
import { Input } from './Input';
import { ModalHeader } from './ModalHeader';
import { SummaryText } from './SummaryText';
import { Switch } from './Switch';

const StakingModal: React.FC = () => {
    const [inside_card, setInsideCard] = useState(false);

    const dispatch = useAppDispatch();

    const {
        current_pool,
        pools,
        modal_state: current,
        withdraw_percent,
    } = useAppSelector((state) => state.staking);

    const open =
        useAppSelector((state) => state?.layout?.staking_modal_open) || false;

    const pool = pools.find(({ id }) => id === current_pool);

    const { bounty_data } = useBounty(
        pool?.contract_idx,
        pool?.chain_id,
        pool?.id,
        pool?.type,
    );

    const is_liquidity = ['legacy-liquidity', 'liquidity'].includes(pool?.type);

    useEffect(() => {
        // add class to body when modal is open to prevent scrolling
        if (open) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [open]);

    return (
        <Transition
            data-testid="transition"
            show={open}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={`min-w-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-[70] outline-none focus:outline-none text-white overflow-x-hidden bg-surface-level-one bg-opacity-80`}
            onMouseDown={() => {
                if (!inside_card) {
                    dispatch(setStakingModalOpen(false));

                    setTimeout(() => {
                        dispatch(setModalState(StakingModalTabs.Deposit));
                        dispatch(setDepositAmount('0'));
                        dispatch(setToken1DepositAmount('0'));
                    }, 300);
                }
            }}
        >
            <Transition.Child
                data-testid="transition-child"
                className="w-full max-w-screen sm:max-w-sm ml-auto my-auto shadow-lg bg-surface-level-one min-h-screen border-l-2 border-border border-opacity-5 relative flex flex-col justify-between"
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
                id="staking-modal"
            >
                <ModalHeader />

                <div className="p-4 mb-auto">
                    <div className="bg-surface-level-two p-4 text-center rounded border border-border border-opacity-5">
                        <Switch />
                        {current === StakingModalTabs.Deposit && (
                            <Input pool={pool} />
                        )}
                        {is_liquidity &&
                            current === StakingModalTabs.Withdraw && (
                                <SliderInput
                                    className="mt-4"
                                    onChange={(e) =>
                                        dispatch(
                                            setWithdrawPercent(
                                                parseInt(e.target.value),
                                            ),
                                        )
                                    }
                                    max={100}
                                    min={5}
                                    step={5}
                                    value={withdraw_percent.toString()}
                                    is_percentage={true}
                                />
                            )}
                        {current === StakingModalTabs.Bounty && (
                            <div>
                                <Card
                                    className="h-[95px] my-5 flex items-center justify-center
                                    "
                                    surface="three"
                                >
                                    <DataLines
                                        variant="verticle"
                                        top_size="2xl"
                                        is_top_bold
                                        values={[{ value: bounty_data.value }]}
                                        label={bounty_data.label}
                                    />
                                </Card>
                                <Typography variant="secondary">
                                    {bounty_data.text}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <SummaryText />
                </div>
                <Footer />
            </Transition.Child>
        </Transition>
    );
};

export default StakingModal;
