import { BigNumber } from '@ethersproject/bignumber';

export const calculateMultiAssetRewards = (
    reward: BigNumber,
    ratio: string,
    is_reward_above_input: boolean,
): BigNumber => (is_reward_above_input ? reward.div(ratio) : reward.mul(ratio));

export const parseNativeToken = (symbol: string): string => symbol.slice(1);

export const getWithdrawAmount = (
    amount: BigNumber,
    withdraw_percent: number,
): BigNumber => {
    const withdraw_amount = BigNumber.from(amount || 0)
        .mul(withdraw_percent || 0)
        .div(100);

    return withdraw_amount;
};
