import React from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import { Button } from '@src/components';
import { useStakingMethods } from '@src/components/Staking/hooks';
import { isLegacyStakingType } from '@src/config';
import { staking_contract_versions } from '@src/constants';
import { useAppSelector, useTokenApproval, useTokenBalance } from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { ContractType } from '@src/ts/constants';
import { removeCommas } from '@src/utils/web3';

export const StandardDeposit: React.FC = () => {
    const { account } = useWeb3Onboard();
    const { contract_manager } = useGlobalContext();
    const { current_pool, pools, deposit_amount } = useAppSelector(
        (state) => state.staking,
    );

    const {
        input_token: [token],
        type,
        end_date,
        chain_id,
    } = pools.find(({ id }) => id === current_pool);

    const is_legacy = isLegacyStakingType(type);

    const staking_address = contract_manager.getContractAddress(
        staking_contract_versions[type],
        chain_id,
    );

    const balance = useTokenBalance(token.address, chain_id);

    const compounder_address = contract_manager.getContractAddress(
        is_legacy ? ContractType.LegacyVault : ContractType.Vault,
        chain_id,
    );

    const { decimals, address: input_addr } = token;

    const cant_deposit = Number(removeCommas(deposit_amount || '0')) === 0;
    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount || '0'),
        decimals,
    );

    const { handleDeposit, loading } = useStakingMethods();

    const {
        approved,
        handleApprove,
        loading: approve_loading,
    } = useTokenApproval(
        input_addr,
        account,
        ['compound', 'legacy-compound'].includes(type)
            ? compounder_address
            : staking_address,
        parsed_deposit.toString(),
        chain_id,
    );

    const pool_is_expired = dayjs().isAfter(dayjs(end_date || new Date()));

    const deposit = BigNumber.from(parsed_deposit);

    const more_than_available = deposit.gt(balance);

    const deposit_disabled =
        pool_is_expired || cant_deposit || more_than_available;

    return (
        <div className="flex space-x-5">
            <Button
                className="flex-1"
                loading={approve_loading}
                disabled={approve_loading || deposit_disabled || approved}
                onClick={handleApprove}
            >
                1. Enable
            </Button>

            <Button
                className="flex-1"
                disabled={loading || deposit_disabled || !approved}
                onClick={handleDeposit}
                loading={loading}
                id={'current-version'}
            >
                2. Deposit
            </Button>
        </div>
    );
};
