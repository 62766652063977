import { CONTRACT } from '@src/config';
import { BaseTokenSymbol } from '@src/contracts/index';
import { StakingPool } from '@src/ts/interfaces';

const IconPart: React.FC<{
    img: string;
    symbol: string;
    action: string;
    dark: boolean;
}> = ({ img, symbol, action, dark }) => {
    return (
        <div
            className={`px-3 py-2 rounded flex border-border-border border-opacity-5 space-x-2 ${
                dark ? 'bg-surface-level-three' : 'bg-surface-level-two'
            }`}
        >
            <img
                data-testid="staking-icon-img"
                src={img}
                alt={symbol}
                height={40}
                width={40}
            />
            <div>
                <h2 className="lg:text-xl">
                    <strong data-testid="icon-symbol">{symbol}</strong>
                </h2>
                <p className="text-secondary text-xs">{action.toUpperCase()}</p>
            </div>
        </div>
    );
};

const LiquidityIconPart: React.FC<{
    imgs: string[];
    symbols: string[];
    dark: boolean;
}> = ({ imgs, symbols, dark }) => {
    return (
        <div
            className={`px-3 py-2 rounded flex border-border-border border-opacity-5 space-x-2 ${
                dark ? 'bg-surface-level-three' : 'bg-surface-level-two'
            }`}
        >
            <div className="relative w-12">
                <img
                    data-testid="liq-staking-icon-img-1"
                    src={imgs[0]}
                    alt={symbols[0]}
                    height={30}
                    width={30}
                    className="absolute bottom-0"
                />
                <img
                    data-testid="liq-staking-icon-img-2"
                    src={imgs[1]}
                    alt={symbols[1]}
                    height={30}
                    width={30}
                    className="absolute top-0 left-4"
                />
            </div>

            <div>
                <h2 className="lg:text-xl">
                    <strong data-testid="icon-symbol">
                        {symbols[0]} / {symbols[1]}
                    </strong>
                </h2>
                <p className="text-secondary text-xs">LIQUIDITY STAKE</p>
            </div>
        </div>
    );
};

export const Icon: React.FC<{
    pool: StakingPool;
    dark?: boolean;
}> = ({ pool, dark = false }) => {
    const {
        input_token = [
            CONTRACT.BaseToken[pool.chain_id],
            CONTRACT.BaseToken[pool.chain_id],
        ],
        reward_token,
        type,
    } = pool || {};

    const is_liquidity = ['liquidity', 'legacy-liquidity'].includes(type);

    return (
        <div className={`flex space-x-2`}>
            {is_liquidity ? (
                <LiquidityIconPart
                    dark={dark}
                    imgs={[
                        `/icons/${
                            input_token[0]?.symbol?.toUpperCase() ||
                            BaseTokenSymbol
                        }.svg`,
                        `/icons/${
                            input_token[1]?.symbol?.toUpperCase() ||
                            BaseTokenSymbol
                        }.svg`,
                    ]}
                    symbols={[input_token[0]?.symbol, input_token[1]?.symbol]}
                />
            ) : (
                <IconPart
                    dark={dark}
                    img={`/icons/${
                        input_token[0]?.symbol?.toUpperCase() || BaseTokenSymbol
                    }.svg`}
                    symbol={input_token[0]?.symbol}
                    action="Stake"
                />
            )}
            <IconPart
                dark={dark}
                img={`/icons/${
                    reward_token?.symbol?.toUpperCase() || BaseTokenSymbol
                }.svg`}
                symbol={reward_token?.symbol}
                action="Earn"
            />
        </div>
    );
};
