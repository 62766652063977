import React from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import { Button } from '@src/components';
import { useStakingMethods } from '@src/components/Staking/hooks';
import { CONTRACT } from '@src/config';
import { staking_contract_versions } from '@src/constants';
import { useAppSelector, useTokenApproval, useTokenBalance } from '@src/hooks';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { PoolToken } from '@src/ts/interfaces';
import { removeCommas } from '@src/utils/web3';

export const LiquidityDeposit: React.FC = () => {
    const { account } = useWeb3Onboard();

    const {
        current_pool,
        pools,
        deposit_amount = '0',
        token1_deposit_amount = '0',
    } = useAppSelector((state) => state.staking);
    const { contract_manager } = useGlobalContext();
    const pool = pools.find(({ id }) => id === current_pool);

    const {
        input_token = [
            CONTRACT.BaseToken[pool.chain_id],
            CONTRACT.BaseToken[pool.chain_id],
        ],
        type,
        end_date,
        is_native: [is_native, pos],
        chain_id,
    } = pool;
    const staking_addr = contract_manager.getContractAddress(
        staking_contract_versions[type],
        chain_id,
    );

    const [token0, token1] = input_token;

    const cant_deposit = Number(removeCommas(deposit_amount)) === 0;

    const parsed_deposit = parseUnits(
        removeCommas(deposit_amount),
        token0.decimals,
    );
    const parsed_deposit_token1 = parseUnits(
        removeCommas(token1_deposit_amount),
        token1.decimals,
    );

    const { handleDeposit, loading } = useStakingMethods();

    const {
        approved: token0_approved,
        handleApprove: handleToken0Approve,
        loading: approve_token0_loading,
    } = useTokenApproval(
        token0.address,
        account,
        staking_addr,
        parsed_deposit.toString(),
        chain_id,
    );

    const {
        approved: token1_approved,
        handleApprove: handleToken1Approve,
        loading: approve_token1_loading,
    } = useTokenApproval(
        token1.address,
        account,
        staking_addr,
        parsed_deposit.toString(),
        chain_id,
    );

    const token0_balance = useTokenBalance(
        token0.address,
        chain_id,
        is_native && pos === 0,
    );
    const token1_balance = useTokenBalance(
        token1.address,
        chain_id,
        is_native && pos === 1,
    );

    const pool_is_expired = dayjs().isAfter(dayjs(end_date || new Date()));
    const more_than_available =
        BigNumber.from(parsed_deposit).gt(token0_balance) ||
        BigNumber.from(parsed_deposit_token1).gt(token1_balance);

    const deposit_disabled =
        pool_is_expired || cant_deposit || more_than_available;

    const enable_button_data = [
        [token0_approved, token0, approve_token0_loading, handleToken0Approve],
        [token1_approved, token1, approve_token1_loading, handleToken1Approve],
    ].filter((_, idx) => !is_native || (is_native && pos !== idx)) as [
        boolean,
        PoolToken,
        boolean,
        () => Promise<void>,
    ][];

    return (
        <div className="flex space-x-5">
            {enable_button_data.filter(([approved]) => approved).length ===
            enable_button_data.length ? (
                <Button
                    className="flex-1"
                    disabled={loading || deposit_disabled}
                    onClick={handleDeposit}
                    loading={loading}
                    id={'current-version'}
                >
                    Deposit
                </Button>
            ) : (
                enable_button_data.map(
                    ([approved, token, loading, handleApprove], idx) => (
                        <Button
                            key={`${token.symbol}-${idx}`}
                            className="flex-1"
                            loading={loading}
                            disabled={loading || approved}
                            onClick={handleApprove}
                        >
                            {enable_button_data.length > 1 && `${idx + 1}. `}
                            Enable {token.symbol}
                        </Button>
                    ),
                )
            )}
        </div>
    );
};
